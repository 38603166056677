.first-bill-verification-modal-container .modal-content-container {
    padding-bottom: 15px;
}

.first-bill-verification-modal {

    .button-container {
        @media @wider-than-phablet {
            margin-top: 30px;
        }

        button {
            margin-bottom: 1.5rem;
        }
    }

    .verification-sms-section, .verification-email-section, .verification-pdiv-section {
        padding-bottom: 50px;
    }

    .modal-subheader {
        max-width: 360px;
        margin-left: auto;
        margin-right: auto;

        @media @wider-than-phablet {
            font-size: 1.8rem;
        }
    }

    .verification-pdiv-section {
        .modal-subheader {
            @media @wider-than-phablet {
                font-size: 2.2rem;
            }
        }

    }
}

