
.payment-content {

	padding-top: 20px;
	padding-bottom: 20px;
	height:100%;
	min-height: 350px;

	@media @wide-enough-for-left-column {
		min-height: 540px;
	}

	//.center-content-block();
	padding-top: 10%;
	padding-bottom: 10%;

	position: relative;

	.payment-workbench {

		width: 100%;
		text-align: center;

		&.ng-enter {
			position: absolute;
		}

		// when this content area grows
		// center the content to the middle
		//.center-content-block-container();
	}

	.payment-plan-next-step-button {
		font-weight: 600;
	}

	.payment-next-step-button {
		margin-top: 15px;
		font-weight: 600;
	}

	input, select {
		font-size:2rem;
	}

	button {
		font-size: 14px;
		line-height: 18px;
		padding-top: 15px;
		padding-bottom: 15px;
		white-space: normal;
		font-weight: 400;
	}

	.button-group {
		margin-bottom: 25px;

		button {
			height: auto;
			font-size: 12px;
			font-weight: 600;
			padding-top: 12px;
			padding-bottom: 12px;
			border-right: 1px solid @global-color-secondary;

			&:not(:last-child) {
				@media @wide-enough-for-left-column {
					border-right: none;
				}
			}


		}
	}

	.payment-plan-options,
	.financing-options {
		.u-separator {
			margin-top: 30px;
    		margin-bottom: 30px;
		}
	}

	.financing-page {
		&,
		.financing-options {
			.u-separator {
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}
	}

	.financing-logo {
		max-height: 100px;
		max-width: 400px;
		display: block;
		margin: 0 auto 25px auto;
	}

	.financing-disclaimer {
		white-space: pre-wrap;
		color: #888;
		font-size: 13px;
		min-width: 75%;
		margin: 5px auto 10px auto;
		font-style: italic;
		text-align: left;

		&.attention {
		    font-size: 16px;
		    font-style: normal;
		    font-weight: bold;
		    margin: 20px auto 20px auto;
		}

		&.normal {
			font-style: normal;
		}

		&.u-center {
			text-align: center;
		}

		@media @wider-than-phablet {
			min-width: 75%;
			max-width: 75%;
		}
	}

	.financing-option-detail {
		font-size: 110%;

		&.big {
			font-size: 1.3em;
		}
		&.bold {
			font-weight: bold;
		}
		&.small {
			font-size: .9em;
		}

		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	.financing-summary-option {
	    margin: 28px auto;
	    font-size: 17px;
	}

	.financing-page,
	.financing-options {
		.interaction-feedback {
			text-align: center;
		}

		.button-loader-container {
			float: right;
			margin-right: 15px;

			.button-loader {
				position: absolute;
				margin-top: -7px;
			}
		}

		button.big {
			margin-bottom: 2rem;
		}

		button[disabled],
		button[disabled]:hover,
		button[disabled]:focus,
		button[disabled]:active {
			background-color: transparent;
			color: @global-color-secondary;
			border-color: @global-color-secondary;
		}
	}

	.financing-footer {
		.u-separator {
			margin-top: 50px;
		}
	}

	select {
		min-width: 100%;
	}

	form {
		// bind disclaimer width to the form
		.financing-disclaimer {
			min-width: 100%;
			max-width: 100%;
		}

		@media @wider-than-phablet {
			min-width: 60%;
			max-width: 60%;
			margin-left: auto;
			margin-right: auto;

			&.wider {
				min-width: 75%;
				max-width: 75%;
			}

			// bind button widths to the form
			button {
				min-width: 100%;
				max-width: 100%;
			}
		}
	}

	@media @wider-than-phablet {
		button,
		.payment-date,
		.interaction-feedback {
			min-width: 75%;
			max-width: 75%;
		}

		.payment-plan-options {
			button {
				min-width: 60%;
				max-width: 60%;
			}
		}
	}

	.listing-items {
		padding-left: 0;
	}
	.listing .listing-item {
		text-align: left;
		padding-left: 15px;
		text-indent: 0;
		cursor: pointer;

		&.uneditable {
			cursor: unset;
		}


		&.errored {
			&,
			.listing-item-secondary-desc {
				color: @global-color-warning;
			}

			.iconic * {
				fill: @global-color-warning;
			}
		}
	}
	.listing-items .listing-item-icon .iconic {
		fill: @global-color-secondary;
		transition: transform ease 0.2s;
		transform-origin: 50% 50%;
	}

	// confirmation listing elements
	@media @wider-than-phablet {
		.listing:hover {
			.listing-item .iconic{
			}
			.listing-item:hover .iconic{
				transform: scale(1.5);
			}
		}

		.listing .listing-item {
			padding-left: 75px;
			margin-bottom: 15px;
		}
	}

	.payment-date {
		.u-center();
		display: none;
		@media @wider-than-phablet {
			display: inline;
		}
	}

	.date-interaction-feedback {
		clear: both;
		padding-top: 20px;
		margin-bottom: -20px;
	}

	.method-step .interaction-feedback {
		padding-left: 0px;
		padding-right: 0px;
	}

	/* make the cc icon is legible */
	[for="cc_number"] .iconic {
		height: 35px;
		width: 35px;
		margin-top: 7px;
	}

	[credit-card],
	[expiry] {
		word-spacing: 5px;
	}

	.explanation.bad-debt {
		margin-top: 10px;
		font-size: 1.3rem;
		margin-left: auto;
	    margin-right: auto;
	    max-width: 300px;
	}
}

.flow-question {
	font-size: 1.9rem;
	line-height: 2.6rem;
	text-align: center;
	margin: auto;
	margin-bottom: 25px;
	max-width: 70%;

	&.bold {
		font-weight: bold;
	}

	&.wide {
		max-width: 100%;
	}

	&.title {
		margin-bottom: 22px;
	}

	@media @wider-than-phablet {
		font-size: 2.5rem;
	}
}

.flow-subquestion {
	font-size: 1.8rem;
	line-height: 2.6rem;
	text-align: center;
	margin-bottom: 25px;

	&.skinny {
		margin-left: 50px;
		margin-right: 50px;
		margin-bottom: 10px;
	}

	&.title {
		margin-bottom: 10px;
	}

	&.subtitle {
		margin-bottom: 4px;
	}

	.light {
		color: @global-color-main-grey;
	}
}

.disclaimer-header {
    font-size: 1.9rem;
	line-height: 2.6rem;
    text-align: center;
    font-weight: bold;
    font-style: italic;
	margin-bottom: 25px;
}

.disclaimer-subheader {
    font-size: 1.8rem;
	line-height: 2.6rem;
    margin-top: 25px;
	margin-bottom: 25px;
    font-weight: bold;
}

.disclaimer-subheader-no-margin {
	margin-top: 0;
}

.disclaimer-scrolling {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 40vh;
    min-height: 200px;
    border: solid 1px @global-color-light-grey;
    padding: 8px;
 }

.consentUnderline {
    text-decoration: underline;
}

p.small-margin {
	margin: 0;
	line-height: 1.2em;
}

.eCheck-authorization {
		color: darken(@global-color-light-grey, 25%);
		text-align: left;
		display: inline-block;
		margin-top: 15px;
		margin-left: 80px;
		margin-right: 80px;
		line-height: 1.2em;
}
/***************
LEFT COLUMN
***************/
.payment-left-column, .payment-left-column-persist {
	display: none;

	@media @wide-enough-for-left-column {
		display: block;
	}
}

.payment-progress {
	padding: 5% 0 0;

	@media @wide-enough-for-left-column {
		display: none;
	}
}


/***************
HEADER
***************/
.payment-header {
	.global-z-index-for('payment-header');
	position: absolute;
	top:10px;
	color: #fff;

	@media @wide-enough-for-left-column {
		display: none;
	}
}


/**************
Apple Card Scann Inputs
**************/
.apple-scann-exp {
	width: 1px;
	height: 1px;
	margin-left: -999999px;
	position: fixed;
	display: block;
}
