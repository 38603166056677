.messages {
	.block-segment {
		margin-top: 20px;
		@media @wide-enough-for-left-column {
			&:first-child {
				margin-top: 0;
			}
		}
	}

	.block-segment-subheader {
		padding-top: 5px;
	}

	.block-segment-primary-action {
		margin-top: 10px;
	}
}

.messages-account-number {
	font-size:1.4rem;
}

.messages-time-updated {
	font-size: 14px;
}


/**********************
THREAD DETAILS
**********************/
.message-details {

	padding: 20px;
	background: #fff;

	@media @wide-enough-for-left-column {
		min-height: 600px;
	}


	.message-headings {
		.global-normal-weight-font();
		font-size: 17px;
		margin-bottom: 15px;
		line-height: 20px;
		color: #666;

		.message-provider {
			font-weight: bold;
			font-size: 20px;
			color: #444;
			margin-bottom: 5px;
		}

	}

	.message {
		margin-bottom: 30px;

	}

	.type-indicator {
		float:left;

		.iconic {
			width: 10px;
			height: 10px;
		}

		&.type-patient svg * {
			fill: @global-color-primary-light;
		}
		&.type-provider svg * {
			fill: @global-color-secondary-light;
		}
		&.type-admin svg * {
			fill: @global-color-main-grey;
		}
	}

	.message-content {
		margin-left: 7%;
		@media @wider-than-mobile {
			// margin-left: 40px;
		}
		&.type-admin {
			font-weight: bold;
		}
	}

	.message-header {
		font-size: 13px;
		color: @global-color-dark-grey;
	}

	.message-attachments {
		.file {
			margin-bottom: 5px;
			margin-right: 5px;
			background-color: #efefef;
			padding: 3px 9px 6px 8px;
			display: inline-block;

			a {
				color: #4cc2f7;
			    font-size: 13px;
			    text-decoration: none;

			    &:hover {
				    color: #198FC4;
				}
			}

			.paperclip{
				width: 12px;
			    margin-right: 5px;
			}
		}
	}

	.message-create-area {

		margin-top: 20px;

		&:after {
			.u-cf();
		}

		textarea {
			resize:vertical;
			width:100%;
		}

		button {
			min-width: 100%;
			margin-left: -3.5%; // to fix the offset from message-content left margin
			float: right;

			@media @wider-than-phablet {
				min-width: 0;
			}
		}
	}

	.message-contents {
		white-space: pre-line;
	}

	// We are wrapping the text that instructs how to
	// open a new message. We only want the inner span to
	// reflect the hover state
	.message-closed-start-new {
		span {
			.link();
		}
	}
}



/****************
LEFT COLUMN
****************/
.message-details-logo {
	padding-top: 15px;
}



/****************
COMPOSE MODAL
****************/
.composition-modal-container {

	.compose-content {
		padding-top: 40px;
	}

	.question {
		font-size: 1.9rem;
		line-height: 2.6rem;
		text-align: center;
		margin-bottom: 25px;

		@media @wider-than-phablet {
			font-size: 2.5rem;
		}
	}

	input,
	select {
		margin-bottom: 5px;

		&[disabled] {
			.global-normal-weight-font();
		}
	}

	textarea {
		margin-top: 15px;
		width: 100%;
	}

	@media @wider-than-phablet {
		.compose-options-listing {
			max-height: 310px;
			overflow: auto;
		}
	}

}







