a,
.link {
	color: @global-color-secondary-light;
	cursor: pointer;

    &.inline {
        white-space: nowrap;
    }
	
	&:hover {
		color: lighten(@global-color-secondary-light, 12%);
	}

	&.active {
		text-decoration: underline;
	}

	&.disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.6;
    }
}

//--------------------
// Special states
//--------------------
.link-subtle-on-light {	
	color: @global-color-darkish-grey;
	text-decoration: none;	

	&:hover {
		text-decoration: underline;
	}
}

