
/*
	UTILITIES
	these are your quick helpers to do common things
*/


/* Width and Positioning Utilities
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.u-full-width {
	width: 100%;
	box-sizing: border-box;
}

.u-max-full-width {
	max-width: 100%;
	box-sizing: border-box;
}

.u-pull-right {
	float: right;
}

.u-pull-left {
	float: left;
}

.u-center {
	text-align: center;
}

.u-left {
	text-align: left;
}

.u-right {
	text-align: right;
}

.u-center-vertical {
    vertical-align:middle;
    display:inline-block;
}

.u-bold {
	font-weight: bold;
}

/* Clearing
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* Self Clearing Goodness */

.u-cf {
	content: "";
	display: table;
	clear: both;
}



/* Debugging classes
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.u-debug-bg {
	background: blue !important;
}

.u-debug-border {
	border:1px solid blue !important;
}

/* Momentum scrolling for silky scrolling interaction
–––––––––––––––––––––––––––––––––––––––––––––––––– */
// Removing the need for overflow scroll to enable momentum scrolling
// as an older version of android (Matt's 4.3 Galaxy Nexus for example) breaks
// when this is applied
// .u-momentum-scroll {
// 	-webkit-overflow-scrolling: touch;
// }


/* mobile conditional views
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.u-not-mobile {
	display: none;

	@media @wider-than-mobile {
		display: block;
	}
}

.u-only-mobile {
	display: block;

	@media @wider-than-mobile {
		display: none;
	}
}



.u-separator {
	clear: both;
	margin: 0 auto;
	border-top:2px dotted @global-color-light-grey;
	background: #fff;
	padding:5px;
}

.u-truncate {
	width: 250px;
  	white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;
}


@keyframes zoom-emph {
    0%    { transform: rotate(0deg); }
    33%    { transform: rotate(5deg) translateZ(0); }
    66%    { transform: rotate(-5deg) translateZ(0); }
    100%      { transform: rotate(0deg); }
}

.u-emphasize {
	backface-visibility: hidden;
	animation: zoom-emph 0.3s ease-in 2;
}
