
.payment-form-content {
	height:100%;
	min-height: 350px;
	position: relative;
    margin-top: 30px;

	input, select {
		font-size:2rem;
	}

	button {
		font-size: 14px;
		line-height: 18px;
		padding-top: 15px;
		padding-bottom: 15px;
		white-space: normal;
		font-weight: 400;
	}

	.button-group {
		margin-bottom: 25px;

		button {
			height: auto;
			font-size: 12px;
			font-weight: 600;
			padding-top: 12px;
			padding-bottom: 12px;
			margin-top: 0px;
		}
	}

	select {
		min-width: 100%;
	}

	@media @wider-than-phablet {
		form {
			min-width: 80%;
			max-width: 80%;
			margin-left: auto;	
			margin-right: auto;

			button {
				min-width: 100%;
				max-width: 100%;
			}
		}

		button,
		.interaction-feedback {
			min-width: 75%;
			max-width: 75%;
		}

		button {
			margin-top: 25px;
		}
	}

	.listing-items {
		padding-left: 0;
	}
	.listing .listing-item {
		text-align: left;
		padding-left: 15px;
		text-indent: 0;
		cursor: pointer;

		&.uneditable {
			cursor: unset;
		}


		&.errored {
			&,
			.listing-item-secondary-desc {
				color: @global-color-warning;
			}

			.iconic * {
				fill: @global-color-warning;
			}
		}		
	}
	.listing-items .listing-item-icon .iconic {
		fill: @global-color-secondary;
		transition: transform ease 0.2s;
		transform-origin: 50% 50%;
	}

	// confirmation listing elements
	@media @wider-than-phablet {
		.listing:hover {
			.listing-item:hover .iconic{
				transform: scale(1.5);
			}
		}
		
		.listing .listing-item {
			padding-left: 75px;
			margin-bottom: 15px;
		}
	}

	.method-step {
		margin-top: 16px;
	}

	.billing-step {
		margin-top: 10px;
	}

	.confirm-step {
		margin-top: 14px;
	}

	.method-step .interaction-feedback {
		padding-left: 0px;
		padding-right: 0px;
	}

	/* make the cc icon is legible */
	[for="cc_number"] .iconic {
		height: 35px;
		width: 35px;
		margin-top: 7px;
	}

	[credit-card],
	[expiry] {
		word-spacing: 5px;
	}
}

.payment-form-modal-container .modal-content-container {
	padding: 15px 15px 25px 15px;
}