
// Print ONLY stylesheet
@media print {

	// items not needed for prints
	#left-column,
	#nav,
	#footer,
	nav,
	.context-helper {
		display: none;
	}

}