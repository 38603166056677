
// ---------------------------
// LAYOUT
// 		this handles your general site layout pieces. Your grids,
// 		specific content structure (nav, main-content, etc.)
// ---------------------------

@import 'grids.less';


// Site Layout variables
@container-width: 900px;
@container-width-larger-than-mobile: 95%;


html, body.js-mask-visible {
	overflow-x: hidden;
}

// Give all of the pieces that legit want full height
// the chance to have 100% height
html, body, #layout {
	height: 100%;
}

body.js-mask-visible {
	overflow: hidden;
}


// ---------------------------
// Container - binds the content to be no bigger
// 		than the max container width
// ---------------------------
.container {
	position: relative;
	width: 100%;
	max-width: @container-width;
	margin: 0 auto;
	padding: 0 5px;
	box-sizing: border-box;

	@media @wider-than-tablet {
		padding: 0 20px;
	}

	@media @wider-than-mobile {
		width: @container-width-larger-than-mobile;
		padding: 0;
	}

	&:after {
		.u-cf();
	}
}

// ---------------------------
// main-content - our apps main content is in this section
// ---------------------------
#main-content {
	.container();

	position: relative;
	padding: 0;
	min-height: 360px;
}


#main-column,
#one-column {
	margin-bottom: 15px;
}

@media @wide-enough-for-left-column {

	// ---------------------------
	// left-column - our side bar positioning
	//		nededs to be slide up along the left of the
	//		main-column
	// ---------------------------
	#left-column, #left-column-persist {
		width: 30%;
	}

	#left-column {
		clear: left;
		min-height: 350px;
		background-color: #fff;
	}

	#left-column-persist {
		float:left;

		/*fixing ipad issue where the left column is above it -- hiding it*/
		position: relative;
		.global-z-index-for('left-column-persist');
	}

	#main-column {
		float: right;
		width: 68%;
	}



	// EQUAL HEIGHT COLUMN TEST

	#left-column,
	#main-column {
		&:before {
		    content: '';
		    position: absolute;
		    top: 0;
		    bottom: 0;
		    z-index: -1;
		    left: 0;
		}
	}
	
	#left-column,
	#one-column,
	#main-column.fill-content-bg {
		&:before {
			content: '';
		    background: #fff;
		}
	}

	#left-column {
		&:before {
			width: 30%;
		}
	}

	#main-column {
		&:before {
			left: 32%;
			width: 68%;
		}
	}
}


// ---------------------------
// footer
// ---------------------------
#footer {
	.u-cf();
	width:100%;
}


// ---------------------------
// bg-mask - overlays the content of the page
// 		with this one single resource. Used as the 
//		overlay for the content when the nav is open
//		or a modal is open.
// ---------------------------
#bg-mask {
	visibility: hidden;

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	.global-z-index-for('bg-mask');

	// todo optimize transitions
	transition: all 0.3s ease;

	opacity: 0;

	.js-mask-visible & {
		visibility: visible;
		opacity: 1;
	}
}



// ---------------------------
// app-wrap - allows us to wrap the content that makes
// 		up the app and that will always be visible to the user.
//		Inspect this in the markup to see fully but you will find
//		the header and main-content are inside of this peice.
//		Allows us to shift the content for special events, like the
// 		nav opening.
// ---------------------------
#app-wrap{
	
	&:after {
		.u-cf();
	}

	width:100%;
	//height:100%;
	position: relative;
	//.global-z-index-for('app-wrap');
	
	transition: transform 0.3s;
	
	@media @no-mobile-menu {
		// force desktop to never have the
		// content pushed to the right if it was
		// triggered from a mobile view point
		position: static;
	}

	.js-open-nav & {
		transform: translateX(@global-nav-width)
	}
}