@progress-height: 3px;


@keyframes pulse {
    0%    { background-color: @global-color-secondary; }
    50%      { background-color: darken(@global-color-secondary, 10%); }
    100%      { background-color: @global-color-secondary; }
}

@keyframes blowAway {
    0%    {  }
    100%      { width: 100%; opacity: 0; visibility: none; }
}


.progress-bar {
    // background: @global-color-secondary-light;
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    border: 1px @global-color-secondary solid;
    border-radius: 2px;

    &.progress-blow-away {
    	animation: blowAway .2s linear 0.3s;
    	animation-fill-mode: forwards;
    }
}

.progress-state {
	height: @progress-height;
	// animation: pulse 2s linear infinite;
	transition: transform 0.4s cubic-bezier(0.470, 0.000, 0.745, 0.715); /* easeInSine */
    background: @global-color-secondary;
    transform: translate3d(-50%, 0, 0);
    backface-visibility: hidden;
}