
/*  this mixin is to help tame usage of z-index usage across the site */
.global-z-index-for (@layer) when (isstring(@layer)){
	& when (@layer = 'app-wrap') { z-index: 10; }
	& when (@layer = 'left-column-persist') { z-index: 10; }
	& when (@layer = 'bg-mask') { z-index: 15; }

	// currently only used of demo banner - we may need to re think the z-index
	// if this is applied to other uses
	& when (@layer = 'lower-banner') { z-index: 15; } 
	& when (@layer = 'modal') { z-index: 18; }
	& when (@layer = 'nav-content') { z-index: 12; }
	& when (@layer = 'nav-content--open-nav') { z-index: 16; }
	& when (@layer = 'payment-header') { z-index: 12; }
}

.global-z-index-for (@layer) when not (isstring(@layer)){
	// z-index for usage of 
	z-index: 1;
}


// we use helvetica neue as the default for
// the page so we need a way to provide thicker
// font but not bold
.global-normal-weight-font() {
	font-family: helvetica;
};

.clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}