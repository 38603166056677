.verify-content {
	padding: 0;
	min-height: 275px;
	position: relative;

	@media @wider-than-mobile {
		min-height: 510px;
	}
}

.verify-amount {

	clear:right; // for dbu question

	font-size: 5.5rem;
	line-height: 7rem;

	@media @wider-than-mobile {
		font-size: 6rem;
	}
}

.verify-due-date {
	font-size: 2.5rem;
	text-transform: uppercase;
	color: darken(@global-color-light-grey, 20%);
	margin-bottom: 30px;
	letter-spacing: -1.2px;
	word-spacing: 4px;
	line-height: 25px;

	@media @wider-than-mobile {
		//font-size: 6rem;
	}

	&.verify-disabled-provider-info {
		text-transform: none;
		font-size: 22px;
		text-align: left;
		color: @global-color-dark-grey;
	}
}

.verify-zero-balance {
	.verify-due-date; //styling of zero balance text does not necessarily have to be the same as due date.  it just happens to be as of right now
}

.verify-negative-balance {
	font-size: 2.5rem;
	color: darken(@global-color-light-grey, 20%);
	margin-bottom: 30px;
	letter-spacing: -1.2px;
	word-spacing: 4px;
	line-height: 25px;
}

.listing-item.verify-past-due, .verify-past-due {
	color: @global-color-warning;
}

.verify-pay-btn {
	
	margin-top: 20px;

	@media @wider-than-mobile {
		min-width: 50%;
	}
}



/******************
NOTIFICATION STYLES
******************/
.verify-notification.notification {
	margin: 1% 2% 0;
}

.verify-content .interaction-feedback {
	min-width: 230px;
	width: inherit;
	text-align: center;
	padding-left: 130px;
	padding-right: 130px;
}


/******************
DBU QUESTION MARK STYLES
******************/

// track how much the question mark
// pushes the content
@verify-question-offset : 40px;

.verify-question {
	float: right;
	cursor: pointer;
	margin-top: 20px;
	width: @verify-question-offset;
	font-size: 23px;
	font-weight: bold;
	color: @global-color-secondary;

	&:after {
		.u-cf();
	}

	@media @wider-than-mobile {
  		margin-top: 0;
	}
}

.verify-question-wrapper {
	border: 2px solid @global-color-secondary;
	border-radius: 50px;
}
/******************
DBU CONTENT
******************/
.verify-dbu-arrow {
	// make sure the arrow is centered
	margin-left: @verify-question-offset;
}
.verify-dbu-header {
	font-size: 26px;
}

.verify-dbu-amount-section {
	margin-top: 5px;
	margin-bottom: 10px;
}

.verify-dbu-amount {
	.verify-amount();
	font-size: 4rem;
	margin-top: -11px;

	@media @wider-than-mobile {
		font-size: 4.5rem;
	}
}
.verify-dbu-amount-type {
	font-size: 24px;
	color: #666;
}



/******************
DBU MODAL
******************/
.dbu-current-info {
	font-size: 1.8rem;
	span {
		display: block;
		margin-top: -10px;
		font-size: 2.8rem;
		.global-normal-weight-font(); /* give it a heavier weight*/
	}
}

.dbu-new-info {
	font-size: 2.3rem;
	span {
		display: block;
		margin-top: -10px;
		font-size: 4.5rem;
		.global-normal-weight-font(); /* give it a heavier weight*/
	}
}

.dbu-modal-more-info {

	.modal-header {
		margin-top: 15px;
	}
	.modal-subcontent {
		font-size: 16px;
		line-height: 20px;
	}
	.dbu-reason {
		font-size: 18px;
		margin: 15px auto;
	}

	@media @wider-than-mobile {
		.modal-header {
			margin-top: 30px;
		}
		.modal-subcontent {
			font-size: 23px;
			line-height: 26px;
		}
		.dbu-reason {
			font-size: 23px;
			margin: 45px auto;
			max-width: 90%;
		}
	}
}



/******************
LEFT COLUMN STYLES
******************/
.verify-left-column {
	padding: 0;
}
