.notification-modal {
	.modal-header {
		margin: 0px;
	}

	@media @wider-than-phablet {
		max-width: 400px;
	}

	.modal-text {
		padding: 15px 0px;
		text-align: left;
		color: #777777;
	}

	.modal-content-container {
		padding-bottom: 15px;
		overflow: hidden;
	}

	.modal-subcontent {
		margin-bottom: 0px;
		height: auto;
		padding: 0px;
		@media @no-mobile-menu {
			display: block;
			position: relative;
			max-height: 450px;
			height: auto;
		}
	}

	.loading-indicator-dialog {
		@media @no-mobile-menu {
			margin: inherit;
			top: 0px;
		}
	}

	.truncate {
		.u-truncate();
		@media @no-mobile-menu {
			width: 350px;
		}
	}
}

/**********************
NOTIFICATION LIST
**********************/
.notifications {
	max-height: 350px;
	overflow-y: auto;
	overflow-x: hidden;

	.block-segment {
		cursor: pointer;
		text-align: left;
		border-radius: 0px;
		padding: 0 3px;

		.block-segment-minimal-content {
			transition: all .2s ease-in-out;

			&:hover {
				transform: scale(1.1);
				background-color: #f7f7f7;
			}
		}
	}

	.notification-subheader {
		color: #777777;
		font-size: 1.4rem;
	    color: #9e9e9e;
	}

	.notification-header {
		padding: 3px 0px;
		font-size: 1.8rem;
	    font-family: helvetica;
	    line-height: 18px;
	    color: #616161;
	}

	.separator {
		.u-separator();
		padding: 0;
	}

	.notification-read-indicator {
		svg {
			height: 14px;
		    width: 14px;
		}

		&.unread svg {
		    position: relative;
		    top: 2px;

		    * {
				fill: @global-color-secondary-light;
		    }
		}
	}
}


/**********************
NOTIFICATION DETAIL
**********************/
.notification-details {
	.block-segment {
		overflow: auto;
		max-height: 200px;
		text-align: left;

		&:last-child {
			overflow: visible;
		}
	}

	.modal-subheader {
		margin-bottom: 20px;
	}

	.modal-subcontent {
		overflow: auto;
	}

	.provider-logo,
	.patientco-logo {
		img {
			height: auto;
			max-width: 200px;
		}
	}

	.patientco-logo {
		margin-bottom: 20px;
	}

	.modal-text {
		padding-top: 0px;
		padding-bottom: 20px;
	}

	.notification-subject {
		color: #616161;
	}

	.notification-date {
		margin-top: 2px;
		font-size: 1.2rem;
	    color: #9e9e9e;
	}

	.notification-body {
		margin-top: 10px;
		font-size: 1.5rem;
		color: #616161;
	}
}