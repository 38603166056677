.new-bill-modal {

    .bill-listing {
        max-height: 425px;
        overflow: auto;
    }

    .choose-bill-section button {
        line-height: 18px;
        padding-top: 15px;
        padding-bottom: 15px;
            
        &.existing-bill {
            font-weight: normal;
        }
    }

	.modal-header {
		margin-top: 5%;
		margin-bottom: 5%;
	}

	form button {
		margin-top: 10%;
	}
}