.confirm-pass-modal-container {
    .modal-subheader {
        font-size: 1.8rem;
        margin-top: 10px;
        margin-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .modal-additional-info {
        font-size: 1.8rem;
    }
}