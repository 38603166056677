.receipt-content {

	.block-segment {
		padding: 25px;
		margin-bottom: 25px;
	}

	.receipt-payment-details {
		margin-top: 20px;
		font-size: 18px;
		color: @global-color-dark-grey;

		.receipt-payer {
			.global-normal-weight-font();
			color: #333;
		}
	}

	.logo-container img {
		max-width: 200px;
	}

	.receipt-payment-amount {
		text-align: center;
		font-size: 32px;
		color: #555;
	}

	.receipt-payment-line-items {
		margin-top: 30px;
		max-width: 600px;

		td {
			padding: 6px 10px;
		}

		thead tr {
			background: lighten(@global-color-light-grey, 8%);
		}

		tbody td {
			font-size: 14px;
		}

		.plan-desc {
			display:none;

			@media @wider-than-phablet {
				display: table-cell;;
			}
		}

		// these are the transaction ids
		.desc span {
			color: @global-color-main-grey;
		}

		// these are negative amount actions like
		// amount declined and canceled
		.amount span {
			text-decoration: line-through;
		}

		.amount {
			white-space: nowrap; //needed to prevent negative amounts from taking more than one line
		}

		.receipt-summation {
			font-weight: bold;
			
			td {
				padding-top: 30px;
				color: @global-color-dark-grey;
			}
		}
	}

    .linkable-notification {
        margin-bottom: 20px;
        margin-top: 20px;

        @media @wide-enough-for-left-column {
            margin-top: 0px;
        }
    }

    .info-subtle {
    	padding-left: 20px;
    	padding-right: 20px;
    	margin-top: 40px;
    	text-align: left;
		color: darken(@global-color-light-grey, 25%);
		font-size: 14px;
    }

	.receipt-vendor-info {
		margin: 0 15px;
		width: 90%;

		b {
			white-space: nowrap;
		}
	}
}



/**************
LEFT COLUMN
**************/

#left-column-persist {
	.receipt-created-stamp {
		text-align: center;
		color: darken(@global-color-light-grey, 25%);
		font-size: 14px;
		// margin-top: 20px;
	}

	.receipt-left-col-per .call-out {
		font-size: 25px;
		padding-bottom: 0;
	}
}



