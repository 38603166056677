

@block-segment-expansion-toggle-height: 50px; 

.block-segment {
	background: #fff;
	padding: 10px 10px 0;
	border-radius: @global-radius;

	&:after {
		.u-cf();
	}

	&.bordered-segment {
		border-bottom: 5px solid darken(@global-color-light-grey, 10%);
	}
}

.block-segment-sub {
	.global-normal-weight-font();
	background-color: #999;
	color: #fff;
	margin: 10px -10px 0;
	padding: 10px;
	border-radius: 0 0 @global-radius @global-radius;
	font-size: 13px;
	line-height: 20px;
	padding-left: 20px;
	padding-right: 20px;

	a {
		color: #fff;
		text-decoration: none;
	}

	// account for spacing
	&.block-segment-using-expansion {
		height: @block-segment-expansion-toggle-height;

		.expanded & {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}

/* link wraps the whole provider info*/
.block-segment-full-link {
	color: inherit;
	text-decoration: none;
	&:hover {
		color: #333;
	}
}

// the minimal content sections that just need the 
// padded and presenting with good spacing to make up
// for lack of inner content
.block-segment-minimal-content {
	padding: 10px 15px 15px;
}

.block-segment-header {
	.global-normal-weight-font();
	font-size: 18px;
	line-height: 18px;
	color: #616161;
}

.block-segment-subheader {
	font-size: 18px;
	line-height: 22px;
	padding-top: 10px;
	color: #9e9e9e;

	&.warn {
		color: @global-color-warning;
	}
}


.block-segment-primary-action {
	float: right;
	margin: 3px 0 0;
}

.block-segment-sub-actions-group {
	display: none;
	float: right;


	@media @wider-than-phablet {
		display: block;
	}

	.block-segment-using-expansion & {
		margin-right: @block-segment-expansion-toggle-height - 1; // small offset for equal spacing
	}

	button {
		font-size: 1rem;
		padding: 0px 15px;
		height: auto;
		margin-left: 15px;
	}
}

.block-segment-sub-actions-group-pp {
	float: right;

	@media @wider-than-phablet {
		display: block;

		.block-segment-using-expansion & {
			margin-right: 52px;
		}
	}

	button {
		font-size: 1.2rem;
		height: auto;
		margin-left: 15px;
		margin-bottom: 0px;
	}
}


//*********************
// BLOCK-SEGMENT-EXPANSION
//*********************
.block-segment-expansion {
	margin: 0 -10px;
	border-radius: 0 0 @global-radius @global-radius;
	
	.expanded & {
		border-bottom: 5px solid @global-color-light-grey;
	}
}

.block-segment-expansion-head {
	position: relative;
	width: 100%;
	background: @global-color-light-grey;

	.expanded & {
		min-height: 5px;	
	}
}

.block-segment-expansion-toggle {
	position: absolute;
	left:100%;
	margin-left: -1 * @block-segment-expansion-toggle-height;
	top: -1 * @block-segment-expansion-toggle-height;
	width: @block-segment-expansion-toggle-height;
	height: @block-segment-expansion-toggle-height;
	text-align: center;
	background: @global-color-light-grey;
	cursor: pointer;
	border-bottom-right-radius: @global-radius;
	
	.expanded & {
		border-bottom-right-radius: 0;
	}

	.iconic {

		// we are using the down icon so that the default
		// state still indicates it's a drop down type area
		// the css needs to account for the not expanded state
		// points right. then expanding undos the rotation
		transition: transform ease 100ms;
	    transform-origin: 10px 14px;
	    transform: rotate(-90deg);

		margin-top:10px;
		width: 20px;
		padding: 2px;
		fill: #888;

		.expanded & {
			transform: rotate(0deg);
		}
	}
}

.block-segment-expansion-content {
	display: none;

	.expanded & {
		display: block;
	}
}

// these are currently for mobil only
.block-segment-expansion-head-actions {
	display: none;
	text-align: center;


	.expanded & {
		display: block;
		@media @wider-than-phablet {
			display: none;
		}
	}

	button {
		  min-width: 0;
		  margin-top: 2%;
		  margin-right: 2%;
		  margin-left: 2%;
		  padding-right: 15px;
		  padding-left: 15px;

		  @media @wider-than-mobile {
		  	min-width: 35%;
		  }
	}

}


//*********************
// CENTERED BLOCKS
//*********************
.center-content-block {
	display: table;
	table-layout: fixed;
	width: 100%;
	height: 0; // fixes Firefox vertical centering
}

.center-content-block-container {
	display: table-cell;
	width: 100%;
	vertical-align: middle;
	text-align: center;
}
