
@modal-container-side-padding: 15px;
@modal-actions-offset: 70px;

.modal {
	//background: #fff;

	.global-z-index-for('modal');

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 630px;
	min-width: 320px;
	height: 100%;

	// perspective: 1300px;
	visibility: hidden;
	backface-visibility: hidden;
	
	@media @wider-than-phablet {
		position: fixed;
		top: 50%;
		left: 50%;
		// width: 75%;
		height: auto;
		max-width: 500px;

		transform: translateX(-50%) translateY(-50%); 
	}

	&.modal-visible {
		visibility: visible;
	}

	&.no-close {
		.modal-content-container {
			padding-top: 15px;
		}
	}
}

.modal-content-container {
	
	// we want to keep a tight hold on how wide modals 
	//get on desktop so we will set the max here and reevaluate
	// max-width: 500px;

	color: #333;
	background: #fff;
	position: relative;
	border-radius: 3px;
	margin: 0 auto;
	height: 100vh;
	max-height: 100%;
	overflow: auto;
	padding: 35px @modal-container-side-padding @modal-actions-offset + 15px;
	text-align: center;

	transition: transform 0.2s ease-out;

	transform-style: preserve-3d;
	transform: translateY(35%) rotateX(-80deg);
	transform-origin: 0 100%;
	
	// todo: handle transition of opacity more
	// elegantly 
	opacity: 0;

	@media @wider-than-phablet{
		height: auto;
		max-height: 97vh;
		// account for all endpoints
		form {
			min-width: 80%;
			max-width: 80%;
			margin: 0 auto;
		}
	}


	/*****************
	STATES
	*****************/
	.success & {
		color: #fff;
		background-color: @global-color-primary-light;

		a {
			color: #fff;
			text-decoration: none;
			font-weight: bold;

			&:hover {
				color: #1eaedb;
			}
		}
	}

	.information & {
		color: #fff;
		background-color: @global-color-secondary;
	}

	.modal-visible & {
		transform: translateY(0%) rotateX(0deg);
		opacity: 1;
	}

	.modal-header {
		font-size: 2.5rem;
		margin-bottom: 10px;
		@media @wider-than-phablet{
			font-size: 3.2rem;
		}
	}

	.modal-subheader {
		font-size: 1.8rem;
		margin-bottom: 10px;
		@media @wider-than-phablet{
			font-size: 2.4rem;
		}
	}

	.modal-subcontent {
		margin-bottom: 30px;
		font-size: 18px;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		height: 300px;

		@media @wider-than-phablet{
			font-size: 18px;
			padding-left: 30px;
			padding-right: 30px;
			overflow-y: inherit;
			height: inherit;
		}

		&.small {
			font-size: 16px;
			line-height: 22px;
			margin-bottom: 0px;
		}
	}


	.button-group {
		margin-bottom: 15px;
	}

	iframe {
		width: 100%;
		height: 50vh;
	}

	@media @wider-than-phablet {
		iframe {
		    height: 50vh;
		}
	}

	iframe[seamless]{
		background-color: transparent;
		border: 0px none transparent;
		padding: 0px;
		overflow: hidden;
    }

    .text-left {
    	text-align: left;
    }


}

.modal-close-icon {
	text-transform: uppercase;
	font-weight: 600;
	line-height: 14px;
	display: block;
	padding: 5px 5px;
	cursor: pointer;
	text-align: right;
	position: absolute;
	top: 10px;
	right: 10px;

	& .iconic {
		width:15px;
		height:15px;
		fill: #999;
	}

	.success & .iconic,
	.information & .iconic{
		fill: #fff;
	}
}



.modal-actions {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 15px 5px;
	height: @modal-actions-offset;
	background: #fff;

	button {
		min-width: 40%;
		margin: 0 2% 0 2%;
		padding: inherit;

		&.modal-single-button {
			min-width: 80%;	
		}
	}

	@media (max-height: 375px) {
		position: initial; //when the screen gets too short (e.g. android with soft keyboard loaded), undock it from the bottom and have user scroll to it
	}
}

/*****************
SIMPLE MODAL STYLES
*****************/
.simple-modal {
	.icon-padding {
		padding: 10% 0 5%;
	}
}

/*****************
OPTION MODAL STYLES
*****************/
.option-modal {
    @media @wider-than-phablet {
        max-width: 400px;

        .modal-content-container {
            padding: 35px;
        }
    }

    .modal-header {
        font-size: 2.4rem;
    }

    .option-collection {
        margin-top: 25px;
    }

    &.change-payment-form {
        .confirm-content {
            text-align: left;
            margin-top: 20px;
        } 

        .interaction-feedback {
            padding-left: 0px;
            padding-right: 0px;
            padding-bottom: 20px;
        }
    }     
}


