.confirm-phone-modal-container {

	.modal-header {
        font-size: 3.0rem;
		margin-bottom: 10px;
	}

    .modal-subheader {
        font-size: 2.0rem;
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .more-info {
        font-size: 1.3rem;
        text-align: left;
        margin-right: auto;
        margin-left: auto;
        margin-top: 20px;
        max-width: 80%;
    }
}