
@side-padding: 10px;


.landing-content {
	background-color: transparent;
	.u-cf();
}


.landing-entry-container {
	background: @global-color-primary;
	color: #fff;
	min-width: 295px;

	@media @wider-than-tablet {
		float:left;
		max-width: 350px;
		min-height: 650px;
	}

	a {
		color: #fff;
	}

	.landing-entry-content {
		padding: 3% @side-padding 15px;

		@media @wider-than-tablet {
			padding-top: 10%;
		}

		.input-area-wrapper.errored {
			margin-bottom: 1rem;
		}
	}
}

// positioning of the floating content
// for bigger devices
.landing-secondary-content {
	@media @wider-than-tablet {
		max-width: 55%;
		float: right;
	}
}


.landing-sub-actions {
	text-align: center;
	padding: 20px @side-padding - 4;

	button {
		// .grid-column-width(6);
		width:100%;
		padding-left: 0;
		padding-right: 0;
	}

	@media @wider-than-tablet {
		float: right;
		button {
			width: auto;

			padding-left: 20px;
			padding-right: 20px;
            float: right;
            margin-left: 5px;
		}
	}
}


// TODO: break out into a reusable transition class
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -30%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}


// marketing content section
.landing-info-content {
	padding: 50px @side-padding ;
	font-size: 2rem;
	color: darken(@global-color-light-grey, 40%);
	

	@media @wider-than-tablet {
		animation-name: fadeInDown;
		animation-fill-mode: both;
		animation-duration: 0.9s;
		animation-delay:0.2s;
		font-size: 2.4rem;
		clear:right;
		margin-top: 35%;
	}

	button {
		margin-top: 15px;
		font-size: 13px;
	}

	sup {
		top: -0.75em;
		font-size: .5em;
	}
}



.landing-logo {
	text-align: center;
	margin: 0 auto 5%;
	
	#landing-logo svg {
		width: 140px;
		height: 140px;
	}

	#landing-text svg {
		width: 180px;
		margin-top: 1.5em;
	}

	.landing-logo-name {
		font-family: arial;
		font-size:30px;
		text-transform: uppercase;
		color:#fff;
		font-weight: bold;

		// arail bold leaves a big gap between
		// the P A T -- we are trying to clean that up
		span {
			letter-spacing: -2px;
		}
	}
}






// this will be applied globally to scode inputs with 
// the securecode-input directive applied. If the need 
// to style this grows more than this, move to its own module 
// less file
[securecode-input] {
	text-transform: uppercase;

	/*placeholder shim class*/
	&.empty {
		text-transform: none;
	}

	&::-webkit-input-placeholder {
		text-transform: none;
	}
	&::-moz-placeholder {
		text-transform: none;
	}
	&:-moz-placeholder {
		text-transform: none;
	}
	&:-ms-input-placeholder {
		text-transform: none;
	}
}

.scode-amount-help:hover {
	cursor: pointer;
	text-decoration: underline;
}
.scode-amount-instructions {
	padding-bottom:10px;
	padding-top:10px;
	font-size: 17px;
}

// the image for need help can't get
// certain width or it will overflow and be partially hidden
img.needhelp {
	max-width: 215px;
	@media @wider-than-mobile {
		max-width: 275px;
	}
	@media @wider-than-phablet {
		max-width: 100%;
	}
}

img.needhelp-tw {
	max-width: 340px;
	@media @wider-than-phablet {
		max-width: 100%;
	}
}




