.dialog-flow-link-set {

	background-color: #fff;
	padding: 10px 0;

	.dialog-flow-link {
		
		display: inline-block;
		padding: 0 10px 0 20px;
		position: relative;
		color: darken(@global-color-light-grey, 40%);
		font-size: 1.8rem;
		cursor: default;
		background-color: darken(#fbfbfb, 5%);
		border-top: 1px solid darken(#fbfbfb, 5%);
		border-bottom: 1px solid darken(#fbfbfb, 5%);
		min-width:100px;
		line-height: 36px;
		margin-left: -4px;
		text-align: center;

		@media @wider-than-phablet {
			padding: 0 15px 0 30px;
		}
		
		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}

		&.current {
			//font-weight: 600;
			background-color: darken(#fbfbfb, 10%);
			border-top: 1px solid darken(#fbfbfb, 10%);
			border-bottom: 1px solid darken(#fbfbfb, 10%);

			&::before {
		        border-left-color: darken(#fbfbfb, 10%);
		    }
		      
		    &::after {       
		        border-left-color: darken(#fbfbfb, 5%);
		    }
		}

		&[disabled]{
			color: darken(@global-color-light-grey, 15%);
			text-decoration: none;
			cursor: default;
			border-top: 1px solid darken(#fbfbfb, 10%);
			border-bottom: 1px solid darken(#fbfbfb, 10%);

			background-color: transparent;

			&::before {
		        border-left-color: #ffffff;
		    }
		      
		    &::after {       
		        border-left-color: darken(#fbfbfb, 10%);
		    }
		}

		&:first-of-type {    
	   		padding-left: 5px;
	   		border-left: 1px solid darken(#fbfbfb, 1%);
		}

		&::before, &::after {
	        content: ''; 
	        display: block; 
	        width: 0; 
	        height: 0;
	        border-top: 19px solid transparent; 
	        border-bottom: 19px solid transparent;
	        position: absolute;
	        top: 50%;
	        margin-top: -19px; 
	        left: 100%;
	    }
	      
	    &::before {
	        border-left: 12px solid darken(#fbfbfb, 5%);
	        z-index: 2; 
	    }
	      
	    &::after {       
	        border-left: 12px solid darken(#fbfbfb, 5%);
	        margin-left: 1px;
	        z-index: 1; 
	    }
	}

	.dialog-flow-link-separator {
		display: inline-block;
	}
}