
@header-bg-color: @global-color-primary;
@header-font-color: #fff;
@nav-bg-color: #222;

// ---------------------------
// header - this is the officical wrapper of
// 		the header, green and all!
// ---------------------------
.header {
	background: @header-bg-color;
	color: @header-font-color;
	padding: 10px 0;
	margin-bottom: 15px;

	&:after {
		.u-cf();
	}
}


// ---------------------------
// persistent content - this is content that should stay
// 		visible throughout the responsive states
// ---------------------------
.header-persistent-content {
	// mobile center this content

	width: 18rem;
	margin: 0 auto;
	padding-top:2px;

	// pull it to the left on bigger
	@media @no-mobile-menu {
		width: auto;
		margin: 0;
	}

	a {
		color: #fff;
		text-decoration: none;
	}

	.header-name {
		text-transform: uppercase;
		font-size:2.2rem;
		font-family: arial;
		font-weight: bold;

		// arail bold leaves a big gap between
		// the P A T -- we are trying to clean that up
		span {
			letter-spacing: -2px;
		}
	}

	.header-home-link,
	.header-name {
		// don't allow it to lay under other nav buttons
		// with it's natural 100% width
		max-width: 200px;
		display:block;
	}

	#header-logo {
		& svg, img {
			.u-pull-left();
			margin-right: 5px;
			width: 32px;
			height: 32px;
		}
	}

	#header-text {
		& svg, img {
			.u-pull-left();
			margin-left: 5px;
		    width: 120px;
		    height: 32px;
		}
	}
	
}


// ---------------------------
// nav-icon - this is our hamburger!
// ---------------------------
.nav-icon {
	.hamburger();

	position: relative;
	left: @global-nav-width;
	// make the button fit correctly but
	// give it the biggest touch area without
	// breaking the nav flow
	width:25px;
	padding: 23px 20px 8px 12px;
	//margin: -10px 0px -10px -15px;

	.js-open-nav & {
		.closed-hamburger();
	}


	// never show me for big screens
	@media @no-mobile-menu {
		display: none;
	}
}


// ---------------------------
// nav - the nav is set to sit outside the page flow
// 		of the app-wrap so we can have it push the main-content
//		over as well as be able to flip over and be present on
//		the desktop header. We want this to appear in two different
//		sections so we must have them out of the flow and position
//		correctly.
// ---------------------------
#nav {

	.global-z-index-for("nav-content");

	transition: transform 0.3s;

	// position off screen - mobile first!
	position: absolute;
	top: 0;
	left: -1 * @global-nav-width;
	width: @global-nav-width;
	height: 100%;
	// don't hide overflow because menu needs to
	// be outside and visible
	//overflow: hidden;
	background: @nav-bg-color;
	text-align: center;


	// this state update is what controls the
	// slide menu from opening
	.js-open-nav & {

		.global-z-index-for("nav-content--open-nav");

		transform: translateX(@global-nav-width);
		// tbd if we want to keep the shadow
		box-shadow: 0 0 10px 2px #4D4D4D;
	}


	// respond to the bigger ui states
	// to adapt the nav contents we are going to
	// position the nav contents above the top header
	// then use positioning to overlay our nav with the
	// fixed header
	@media @no-mobile-menu {

		// bound the contents like the header
		.container();

		background-color: transparent;
		left:auto;

		// squeeze actual element down
		// and position it's contents down
		height: 0px;
		overflow: visible;
	}
}


// ---------------------------
// nav-content - this is the actual content of the
//		nav/menu. This section we can position and style
//		them responsively to fit where we need them
// ---------------------------
.nav-content {

	&:after {
		.u-cf();
	}

	@media @no-mobile-menu {

		// we are going to be building from
		// right to left
		.u-pull-right();
		padding: 10px 0 0;
		width:77%;
		margin-left: -3px;
	}

	@media @desktop {
		width:82%;
		margin-left: 0;
	}
}


.nav-items-locations {
	width:100%;
	margin-bottom: 35px;



	a, span {

		width: 100%;
		display: inline-block;
		color:#fff;
		text-decoration: none;
		font-size: 1.45rem;
		letter-spacing: 0.05rem;
		padding: 13px 0;
		font-weight:600;
		text-align: left;
		text-indent: 30px;
		border-bottom: 1px solid #2A342E;

		&:first-child {
			border-top: 1px solid #303830;
		}

		&.active {
			background: #2F3C34;
			border-top: 1px solid #3A4C41;
			border-color: #3A4C41;
		}
	}

	.nav-settings {
		display: none;
	}

	.nav-settings-mobile {
		a {
			text-indent: 50px;
		}
	}

	@media @no-mobile-menu {
		width:auto;
		margin-top: -5px;
		margin-bottom:0;
		float:left;
		position: relative;
		left: 3%;


		&:hover a {



			opacity: 0.5;

			&:hover {
				font-weight: bold;
				letter-spacing: -0.03rem;
				opacity: 1;
			}
		}

		a, span {
			// handles opacity on mouse leave
			// to transition back out
			transition: opacity 0.2s;

			width:auto;
			font-weight: 400;
			text-indent: 0;
			padding: 10px 0 5px;
			margin-left: 25px;
			border: none;

			&:first-child {
				border: none;
				margin-left: 0;
			}

			&:hover {
				//ext-decoration: underline;
			}

			&.active {
				background: transparent;
				border: none;
				font-weight: bold;
				letter-spacing: -0.03rem;
			}
		}

		.nav-settings {
			display: inline-block;
		}

		.nav-settings-mobile {
			display: none;
		}
	}
}

.nav-items-actions {

	float:right;

	.icon {

		@media @no-mobile-menu {
			display: inline;
			margin-left: 22px;
			margin-right: 10px;
			position: relative;

			svg {
				width: 14px;
				position: absolute;
				top: 60%;
				bottom:50%;
				transform: translate(-7px, -50%);

				&.new {
					width: 20px;
					transform: translate(1px, -28px);
				}
			}

			.notif-background svg {
				fill: #5ec385;
				width: 32px;
				transform: translate(-16px, -16px);
			}
		}

		display: none;
	}


	.button {

		/* slower than immediate transition on width is causing
			a page to jump when going to no-mobile-menu size*/
		transition: none;

		border-color: #ddd;
		color: #fff;
		// contents of the nav should horzontally
		// fill the navigation
		min-width: 90%;

		&.button-primary {
			background: @global-color-primary-dark;
			border-color: @global-color-primary-dark;
		}

		&:first-child {
			margin-bottom: 20px;
		}
	}

	@media @no-mobile-menu {

		.button {
			margin-top: 0;
			margin-bottom: 0;
			line-height: 3rem;
			//min-height: 30px;
			min-width: inherit;
			margin-left: 15px;
			padding: 0px 20px;

			&:first-child {
				margin-left: 0;
				margin-bottom:0;
			}
		}
	}

	@media @desktop {
		.button {
			padding: 0px 30px;
		}
	}
}


.nav-back {
	.back-arrow();

	top: 7px;
  	left: 10px;
  	width: 40px;
}

.nav-notifications {
	position: absolute;
	top: 0;
	z-index: 13;
	right: 6px;
	padding: 12px 8px 20px 12px;

	// never show me for big screens
	@media @no-mobile-menu {
		display: none;
	}

	svg {
		width: 18px;
		transform: translate(-3px, 0px);
	}



	transition: transform 0.3s;

	.js-open-nav & {
		transform: translateX(@global-nav-width)
	}
}

.new-notif {
	svg {
		fill: #CB2A23;
		transform: translate(12px, -15px);
		width: 22px;
	}
}

.notif-background {
	svg {
		fill: #5ec385;
		width: 32px;
		transform: translate(4px, 0px);
	}
}


