.contact-modal-container .modal-content-container {
    padding-bottom: 35px;
}

/****************
CONTACT MODAL
****************/
.contact-modal-container {

	input,
	select {
		&[disabled] {
			.global-normal-weight-font();
		}
	}

	textarea {
		margin-top: 15px;
		margin-bottom: 0px;
		width: 100%;
	}

        min-height: 0px;

}

.contact-modal {
	transition: min-height ease 0.2s;
        padding-bottom: 0px;

	// workaround to remove the jumping content when transitioning to forgot password
	&.ng-hide {
		transition: none;
	}

	// give some space between the button and the forms
	@media @wider-than-phablet {
		button {
			margin-top: 20px;
		}
	}

	// we want the lastname icon to only show
	// on mobile, otherwise it is invisible
	@media @wider-than-phablet {
		[for="contact-zendesk-last"]{
			display: none;
		}
		#contact-zendesk-last {
			text-indent: 0;
			margin-left: 0;
		}
	}

	.contact-modal-content {
		min-width: 80%;
		max-width: 80%;
		margin: 0 auto;

		.main-button {
			margin-top: 10px;
			margin-bottom: 0;
			min-width: 100%;
		}

		input[type="button"] {
			padding: 0px 10px;
		}
	}

	.modal-text {
		padding: 15px 0px;
		text-align: left;
		color: rgb(119, 119, 119);
	}

    .info {
        color: @global-color-main-grey;
        font-size: 1.3rem;
        margin-bottom: 0;
    }

    .form-error {
        color: red;
        font-size: 1.3rem;
    }

    .button {
    	&:last-child {
			margin-top: 0px;
		}
    }
}




