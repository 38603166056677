.loading-indicator {

    // this is to match the main content area
    // on the site. It may eventually make sense
    // to consolidate this to a mixin that can 
    // be used, but seems unecessary at this point
    @media @wide-enough-for-left-column {
        float: right;
        width: 68%;

        &.dashboard {
            position: absolute;
            left: 35%;
            top: 50%;
            transform: translate(-1.75em, -2em);
        }
    }

    text-align: center;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity ease-in 0.1s;

    &.js-visible {
        visibility: visible;
        opacity: 0.95;
    }

    .loading-indicator-dialog {
        position: relative;
        top: 15px;
        background-color: @global-color-main-grey;
        color: #fff;
        width: 150px;
        margin: 0 auto;
        padding: 3px 10px;
        border-radius: @global-radius;
        .global-normal-weight-font();
    }
}