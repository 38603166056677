.login-modal-container .modal-content-container {
	padding-bottom: 35px;
}


.login-modal {
	transition: min-height ease 0.2s;

	// workaround to remove the jumping content when transitioning to forgot password
	&.ng-hide {
		transition: none;
	}


	min-height: 450px;

	&.login {
		min-height: 350px;
	}

	button.button-primary {
		margin-top: 20px;
	}
	.button-group button {
		margin-top: 0;
	}

	.button-group {
		margin-top: 20px;
	}

	// we want the lastname icon to only show
	// on mobile, otherwise it is invisible
	@media @wider-than-phablet {
		[for="register-last"]{
			display: none;	
		}
		#register-last {
			text-indent: 0;
			margin-left: 0;
		}
	}

	.modal-header,
	.modal-subheader {
		margin-bottom: 0;
	}

	.register-checkboxes {
		.input-area-wrapper {
			margin-bottom: 0;

			.input-area-field-wrapper {
				padding-top: 10px;
				padding-bottom: 0;
			}

			&:first-child .input-area-field-wrapper {
				padding-top: 0;
			}
		}
	}
}

// special logic around this rules set so we
// only show it when it is needed
.login-password-input-area {
	
	.input-area-rules {
		height: 75px;
		max-height: 0;
		overflow: hidden;
		transition: all ease-in 200ms;

		ul {
			display: none;
		}
	}

	&.input-focused,
	&.errored,
	&.login-password-incorect-blurred {
		.input-area-rules {
			max-height: 80px;

			ul {
				display: block;
			}
		}
	}
}




//*************
// FORGOT PASSWORD
//*************
.login-forgot-password-link {
	text-align: left;
	padding-bottom: 15px;

	@media @wider-than-phablet {
		padding-bottom: 0;
	}

    a {
        .link-subtle-on-light();
    }
}

.forgot-email-input {
	margin: 25px auto 30px;
}

.forgot {
	@media @wider-than-phablet {
		.contact-method button {
			min-width: 80%;
		}
	}

	&.method {
		.modal-header {
			font-size: 2.6rem;
			margin-bottom: 30px;
		}
	}

	&.verify {
		padding-bottom: 35px;
	}
}