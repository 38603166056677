@shield-size : 36px;

// top bar of the footer that has the 
// colored piece for separation and the
// tips dialog for wider devices
.footer-separator {
	width:100%;
	min-height: 20px;
	background-color: @global-color-primary-light;

	.footer-separator-dialog {
		display: none;
		.container();

		b {
			margin-right: 10px;
		}

		@media @wider-than-tablet {
			display: block;
			color: #fff;
			padding: 10px;
		}
	}
}


.footer-content {
	.container();
	.u-cf();

    margin: 10px auto;
}

.footer-shield{
	display: none;

	@media @wider-than-phablet {
		display: block;
	}

	float: left;
	width: @shield-size;
	height: @shield-size;

	* {
		fill: @global-color-primary;
	}
}

.footer-links {
	.inline-list-items();

	width: 100%;

	li {
		width: 100%;
		margin:0;
        line-height: 1;
        padding: 10px 0;
        text-align: center;

		@media @wider-than-phablet {
			.grid-column-width(6);
            padding:0;
            text-align: left;

			//put a margin on the bottom row
			&:nth-child(n+4) {
				margin-top: 12px;
			}
		}
	}

	a {	
		.link-subtle-on-light();

		font-size: 1.8rem;
		padding: 0;
        line-height: 1;

		@media @wider-than-phablet {
			font-size: 1.2rem;
		}
	}

	@media @wider-than-phablet {
		width: 70%;
		float: left;

		ul& {
			margin-left: 10px;
		}
		
		li {
			.grid-column-width(4);
		}
	}


	@media @wider-than-tablet {
		width: 45%;
	}
}

.accepted-cards {
	display: block;
	margin: 15px auto;
	width: 209px;

	@media @wider-than-phablet {
		float: left;
		margin: 15px 0 0 @shield-size + 10px;
	}

	@media @wider-than-tablet {
		margin: 0;
	}

	img {
		height: 36px;
		margin-right: 5px;

		&:last-of-type {
			margin-right: 0;
		}

		&#visa {
			width: 55px;
		}
	}
}

.footer-social {
	display: none;
	
	.inline-list-items();
	float: right;

	li {
		padding: 0;
        margin-left: 10px;
	}

    a {
        display: block;
    }

	@media @wider-than-tablet {
		display: block;
	}
	//todo - show on wider devices
}

.footer-bottom {
	.u-cf();

	width: 100%;
	display: block;
	margin-top: 5px;
	font-size: 1.1rem;

	div {
		float: right;
	}
}

.footer-copyright {
	color: darken(@global-color-light-grey, 30%);
}

.footer-version {
	margin-right: 5px;
	color: #f5f5f5;
}