
#left-column, 
#left-column-persist {
	.block-segment {
		padding: 20px;
	}
	
	.logo-container {

		width: 100%;
		min-height:50px;
		text-align: center;
		

		img {
			max-width: 90%;
			// max-height: 50px;
		}
	}

	button {
		margin-top:10px;
		margin-bottom: 0;


		@media @wider-than-mobile {
			min-width: 50%;
			width: 100%;

			padding-left: 10%;
			padding-right: 10%;
		}
	}

	.separator {
		display: none;
		.u-separator();
		width: 80%;
	}

	@media @wide-enough-for-left-column {
		.separator {
			display: block;
		}	
	}
}

#left-column-persist {
	background-color: #fff;

	.call-out {
		text-align: center;
		font-size: 35px;
		line-height: 35px;
		padding:5px;
	}

	.sub-content {
		text-align: center;
		font-size: 25px;
		color: darken(@global-color-light-grey, 40%);
	}
}