.dashboard-content {

	/* give our containers some room on mobile */
	padding: 0 5px; 
	margin-bottom: 50px;
	
	@media @wide-enough-for-left-column {
		padding: 0;
		min-height: 550px;
	}

	.block-segment {
		margin-top: 20px;

		@media @wide-enough-for-left-column {
			&:first-child {
				margin-top: 0;
			}
		}
	}

	.block-segment-sub {
		clear: both;
	}

    .linkable-notification {
        margin-bottom: 20px;
        margin-top: 20px;

        @media @wide-enough-for-left-column {
            margin-top: 0px;
        }
    }

    .confirm-phone-notification {
    	margin-bottom: 20px;
        margin-top: 20px;

        @media @wide-enough-for-left-column {
            margin-top: 0px;
        }
    }

    .confirm-email-notification {
    	margin-bottom: 20px;
        margin-top: 20px;

        @media @wide-enough-for-left-column {
            margin-top: 0px;
        }
    }


	/******************
	PAST DUE ACCOUNT STYLING
	******************/

	.dashboard-account-warning {
		
		border-bottom-color: @global-color-warning;

		.past-due-stat {
			color: @global-color-warning;
		}
	}

	.block-segment-expansion.contains-hidden-history {
		border-bottom: 0px;
	}

	.view-older-activity {
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}

    .view-zero-balance-accts {
        margin-top: 20px;
        padding-top: 6px;
        padding-bottom: 6px;
    }
}


&.dashboard-accounts {

	.dashboard-account-info {
		padding-top: 15px;
		
		@media @wider-than-phablet {
			padding-left: 10px;
		}
	}

	.block-segment-header {
		font-size:3rem;
	}

	.block-segment-primary-action {
		margin-right: 0;
		padding-left: 15px;
		padding-right: 15px;

		@media @wider-than-phablet {
			margin-right: 10px;	
		}
	}

	.block-segment-sub {
		padding-bottom: 14px;
  		padding-top: 15px;
	}

	.block-segment-sub-actions-group {
		// account for new top padding on -sub
		margin-top: -5px;
	}

	.block-segment-notice {
		background: @global-color-dark-grey;
		margin-bottom: -10px;
		height: 40px;
		padding-top: 11px;
		border-radius: 0;
	}

	.block-segment-sub-actions-group-pp {
		// Helps centering the button to the middle of the payment plan banner 
		margin-top: -7px;
	}

	.block-segment-notice-text {
		font-weight: bold;
	}

	.block-segment-notice-details {
		float: right;
		display: none;
	}

	@media @wider-than-phablet {
		.block-segment-notice-details {
			display: unset;
		}
	}
}




/**************
ACCOUNT HISTORY TABULAR DATA
**************/
.dashboard-summary-table {
	margin: @default-padding;
	width: 90%;

	// global formats
	td {
		font-size: 13px;
		.global-normal-weight-font();
		padding: 2px 10px 2px 0;
		border-bottom: 0;
		cursor: default;
		vertical-align: top;

		a {
			text-decoration: none;
			border-bottom: 1px solid @global-color-secondary-light;

			&:hover {
				border-bottom-color: lighten(@global-color-secondary-light, 20%);
			}
		}
	}

	@media @wider-than-phablet {
		tr:hover td {
			color: #555;	
		}
	}

	.amount {
		text-align: right;
		
		&:after {
			// padding-right:5px;
		}

		&.debit {
			&:before {
				content:'(';
				// padding-right: 2px;
			}
			&:after {
				content:')';
				// padding-left: 2px;
			}
		}
	}

	.desc {
		width: 80%;
	}
	

	// header format
	thead td {
		font-weight: bold;
		color: #555;
	}

	// data format
	tbody td {
		color: #888;
	}
}




/**************
LEFT COLUMN
**************/
#left-column-persist .dashboard-left-col-per {
	text-align: center;
	margin-bottom: 10px;

	.provider-dashboard {
		font-size: 23px;
		line-height: 30px;
	}
}


