.settings-content {
	.block-segment {
		position: relative;
		margin-bottom: 20px;
		padding: 20px;
	}


	.call-out {
		.global-normal-weight-font();
		font-size: 24px;
		margin-bottom: 10px;
	}

	.separator {
		.u-separator();
		width: 100%;
	}

	@media @wider-than-phablet {
		// bring the first and last name closer
		// #details-first {
		// 	width: 65%;
		// }

		// #details-last {
		// 	margin-left: -30%;
		// }

		// hide icon on larger screens
		[for="details-last"]{
			display: none;
		}
	}

	form:after {
		.u-cf();
	}

	input[type="checkbox"] {
		// pull left so we can have the right side
		// label content stay indented on mobile
		// and not wrap around the checkbox
		float:left;
		margin: 0 15px;
	}

	// we currently are using this to account for the labels
	// surrounding a checkbox
	label {
		display: block;
		font-weight: normal;
  		padding-bottom: 5px;
	}

	.method-item {
		padding: 10px 0 0;
		font-size: 16px;

		&>div {
			.clearfix;
		}

		button {
			margin-top: 10px;
		}

		@media @wider-than-phablet {
			.separator {
				margin-top: 25px;
			}

			.method-desc {
				width: 80%;
				float: left;
			}

			button {
				min-width: 20%;
				padding: 0;
				margin: 0;
				float: right;
			}
		}
	}

    .acct-mgmt-item {
        position: relative;
        padding: 10px 0 0;
        font-size: 16px;
        // .global-normal-weight-font();

        button {
            margin-top: 10px;
        }

        @media @wider-than-phablet {
            .separator {
                margin-top: 25px;
            }

            button {
                position: absolute;
                min-width: 200px;
                margin: 0;
                right: 0;
            }
        }
    }


	.settings-details-header {
		float: left;
	}

	.settings-primary-action {
			padding-top: 7px;
			padding-bottom: 4px;
			height: auto;
			margin-top: 25px;


			@media @wider-than-phablet {
				position: absolute;
				top: -7px; // goes above the padding-top of block-segment for alignment
				right: 20px; // should match block-segment padding
				min-width: 0;
				padding: 0 15px;
			}
	}

	.settings-save-note {
		margin: 3px 0 0 10px;
	}

	.help-text {
		color: @global-color-main-grey;
		font-size: 1.2rem;
		text-align:center;
		margin-bottom: 15px;

		@media @wider-than-phablet {
			text-align:right;
			margin-top: 15px;
			margin-bottom: 0px;
		}
	}

	.communication-preferences-block {
		.iconic {
		    float: left;
		    width: 20px;
		    height: 20px;
		    margin: 0 11px;
		}

		.communication-subtitle {
			font-weight: 700;
		}
	}

	.confirm-phone-notification {
		margin-bottom: 20px;
		margin-top: 20px;

		@media @wide-enough-for-left-column {
			margin-top: 0px;
		}
	}

	.confirm-email-notification {
    	margin-bottom: 20px;
        margin-top: 20px;

        @media @wide-enough-for-left-column {
            margin-top: 0px;
        }
    }

}

.settings-left-column {
	display: none;

	@media @wide-enough-for-left-column {
		display: block;
	}

	a.flow-link {
		text-decoration: none;
	}
}


/******************
MODALS
******************/
.settings-delete-method {

	.modal-header {
		margin-top: 20px;
	}

	.modal-subcontent {
		margin-top: 30px;
	}

	button {
		padding-left: 5px;
		padding-right: 5px;
	}
}






