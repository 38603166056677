.terms-modal-container .modal-content-container {
    padding-bottom: 15px;
}

/*********************
TERMS OF SERVICE MODAL
**********************/
.terms-modal {
	transition: min-height ease 0.2s;
    padding-bottom: 0px;

	// workaround to remove the jumping content when transitioning to forgot password
	&.ng-hide {
		transition: none;
	}


    font-family: Helvetica;

    button {
        margin-top: 20px;
    }

    .scrolling {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        height: 50vh;

        @media @wider-than-phablet {
            overflow-y: inherit;
            height: inherit;
        }
    }

    .modal-subheader {
        margin: 10px 5%;
        line-height: initial;
    }

    .input-area-field-wrapper {
        text-align: left;
        margin-top: 10px;
    }

}
