.full-page-notification-content {

	.heading {
        font-size: 3.0rem;
		margin-bottom: 10px;
		font-weight: bold;
	}

    .subheading {
        font-size: 2.0rem;
        margin-top: 10px;
        margin-bottom: 30px;
		font-weight: bold;
    }

    .message {
        font-size: 1.7rem;
    	max-width: 400px;
    	margin: 20px auto;
    }

    button {
    	display: block;
    	margin: 10px auto;

    	@media @wider-than-mobile {
	    	min-width: 350px;
	    	max-width: 350px;
		}
    }
}