.account-verification-modal-container .modal-content-container {
    padding-bottom: 35px;
}

.account-verification-modal {
    .SSN-help-text {
        text-align: left;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .more-info-link {
        text-align: left;
        margin-top: 12px;
        margin-bottom: 12px;

        a {
            .link-subtle-on-light();
        }
    }

    .modal-subheader {
        font-size: 1.8rem;
    }

    .explanation-text {
        font-size: 1.2rem;
    }
}

