/*
	BASE
	these are the element selectors only. Your resets, your
	default look and feel
*/

@import 'normalize.less';


/* Our Base ELement Defaults
–––––––––––––––––––––––––––––––––––––––––––––––––– */
html {
	/* NOTE
	html is set to 62.5% so that all the REM measurements throughout Skeleton
	are based on 10px sizing. So basically 1.5rem = 15px :) */
	font-size: 62.5%;
}

body {
	font-size: 1.5em; /* currently ems cause chrome bug misinterpreting rems on body element */
	line-height: 1.6;
	font-weight: 400;
	font-family: @global-font-family;
	color: @global-color-font;
	background: @global-color-body-bg;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body {
	min-width: 300px;
	height:100%;
}

* {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}


/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */
h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 2rem;
	font-weight: 300;
}

h1 { font-size: 4.0rem; line-height: 1.2;  letter-spacing: -.1rem;  }
h2 { font-size: 3.6rem; line-height: 1.25; letter-spacing: -.1rem;  }
h3 { font-size: 3.0rem; line-height: 1.3;  letter-spacing: -.1rem;  }
h4 { font-size: 2.4rem; line-height: 1.35; letter-spacing: -.08rem; }
h5 { font-size: 1.8rem; line-height: 1.5;  letter-spacing: -.05rem; }
h6 { font-size: 1.5rem; line-height: 1.6;  letter-spacing: 0;       }

/* Larger than phablet */
@media @wider-than-phablet {
	h1 { font-size: 5.0rem; }
	h2 { font-size: 4.2rem; }
	h3 { font-size: 3.6rem; }
	h4 { font-size: 3.0rem; }
	h5 { font-size: 2.4rem; }
	h6 { font-size: 1.5rem; }
}

p {	margin-top: 0; }




/* Form Element Defaults
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/*placeholder shim class*/
.empty {
	color: #a9a9a9;
}
&::-webkit-input-placeholder {
	color: #a9a9a9;
}
&::-moz-placeholder {
	color: #a9a9a9;
}
&:-moz-placeholder {
	color: #a9a9a9;
}
&:-ms-input-placeholder {
	color: #a9a9a9;
}


/* Form Element Defaults
–––––––––––––––––––––––––––––––––––––––––––––––––– */

textarea,
select {
	height: 38px;
	padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
	background-color: #fff;
	box-shadow: none;
	box-sizing: border-box;
}

/* Removes awkward default styles on some inputs for iOS */
input {
	&[type="email"],
	&[type="number"],
	&[type="search"],
	&[type="text"],
	&[type="tel"],
	&[type="url"],
	&[type="password"] {
		-webkit-appearance: none;
	    -moz-appearance: none;
	    appearance: none;
	}
}

textarea {
	-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
	min-height: 65px;
	padding-top: 6px;
	padding-bottom: 6px;
}



/* General Spacing
–––––––––––––––––––––––––––––––––––––––––––––––––– */
input, textarea,
select, fieldset {
	margin-bottom: 1.5rem;
}

pre, blockquote, dl, figure,
table, p, ul, ol, form {
	margin-bottom: 2.5rem;
}


/* Tables
–––––––––––––––––––––––––––––––––––––––––––––––––– */

th, td {
	padding: 12px 15px;
	text-align: left;
	border-bottom: 1px solid @global-color-light-grey;
}

th:first-child,
td:first-child {
	padding-left: 0;
}

th:last-child,
td:last-child {
	padding-right: 0;
}