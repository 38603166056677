.confirm-email-modal-container {

	.modal-header {
        font-size: 3.0rem;
        margin-bottom: 20px;
	}

    .explanation {
    	font-size: 1.3rem;
    }
}